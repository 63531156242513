

import {IonContent, IonIcon, IonInput, IonItem, IonPage} from '@ionic/vue';
import { ref, computed } from 'vue';
import {useRouter} from 'vue-router';
import FixedFooter from '@/components/FixedFooter.vue';
import { caretDownOutline, closeOutline } from 'ionicons/icons';
import { Partner } from '@/models/PartnerModel';
import { Language } from '@/models/LanguageModel';
import { openSelectModal } from '@/services/SelectModalService';
import { PUT } from '@/services/HTTP';
import { SelectOption } from '@/models/SelectOptionModel';
import { AppInstance } from '@/models/AppInstanceModel';
import { presentValidationAlert } from '@/services/Notify';
import {execute} from '@/mixins/LoadingMixin';
import {getAppInstance} from '@/services/AppInstanceService';
import { EMPTY_GUID } from '@/utils/GuidUtil';
import {defineComponent} from '@vue/runtime-core';
import {useStore} from 'vuex';
import {getLanguages} from '@/services/LanguageService';
import {getPartners} from '@/services/PartnerService';

export default defineComponent({
    name: 'EditAppInstance',
    components: {
        IonContent,
        IonPage,
        IonItem,
        FixedFooter,
        IonInput,
        IonIcon
    },
    props: {
        id: {
            type: String,
            required: false,
            default: EMPTY_GUID
        }  
    },
    async ionViewWillEnter() {
        await this.store.dispatch('app/setPageTitle', this.currentId == EMPTY_GUID ? this.language.addAppInstance : this.language.editAppInstance);
        
        await execute(async () => {
            if (this.currentId !== EMPTY_GUID) {
                this.appInstance = await getAppInstance(this.currentId);
                this.canEditSubdomain = this.appInstance.subdomain.trim() === '';
                if (this.appInstance.language === null) {
                    this.appInstance.language = {} as Language;
                }
                if (!this.appInstance.languageFilename) {
                    this.appInstance.languageFilename = this.appInstance.name.replaceAll(" ", "") + '_' + this.language.language.toLowerCase() + '.json';
                }
                if (!this.appInstance.scriptureFilename) {
                    this.appInstance.scriptureFilename = this.appInstance.name.replaceAll(" ", "") + '_' + this.language.scripture.toLowerCase() + '.zip';
                }
                this.originalLanguageData = this.appInstance.languageFile;
            } else {
                this.canEditSubdomain = true;
                this.appInstance = new AppInstance();
            }
            
            const partners = await getPartners();
            this.partnersSelectOptions = partners.map((partner: Partner) => {
                return {
                    value: partner.id,
                    text: partner.name,
                    isChecked: partner.id === this.appInstance.partnerId
                } as SelectOption
            });
            
            const languages = await getLanguages(true);
            this.languageSelectOptions = languages.map((language: Language) => {
                return {
                    value: language.id,
                    text: language.language + ', ' + language.code.toUpperCase(),
                    isChecked: language.id === this.appInstance.languageId
                } as SelectOption
            });
        }, this.language.errors.gettingAppInstances);
    },
    setup: function (props: any) {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        const router = useRouter();
        const canEditSubdomain = ref(false);
        const appInstance = ref(new AppInstance());
        const currentId = computed(() => props.id);
        const originalLanguageData = ref("");
        const partnersSelectOptions = ref({} as SelectOption[]);
        const languageSelectOptions = ref({} as SelectOption[]);
        const languageFile = ref();
        const scriptureFile = ref();
        const displayScriptureRefOnlyOptions = computed(() => [{
            value: "true",
            text: language.value.yes,
            isChecked: appInstance.value.displayScriptureRefOnly
        }, {
            value: "false",
            text: language.value.no,
            isChecked: !appInstance.value.displayScriptureRefOnly
        }]);
        const suspendedOptions = computed(() => [{
          value: "true",
          text: language.value.yes,
          isChecked: appInstance.value.suspended
        }, {
          value: "false",
          text: language.value.no,
          isChecked: !appInstance.value.suspended
        }]);

        const pickPartner = async () => {
            const partnersSelect = await openSelectModal(language.value.partners, 'radio', partnersSelectOptions.value, false);
            partnersSelect.onWillDismiss().then(({data}: any) => {
                partnersSelectOptions.value = data;
                appInstance.value.partnerId = data.find((option: SelectOption) => option.isChecked)?.value;
                appInstance.value.partner.name = data.find((option: SelectOption) => option.isChecked)?.text;
            });
        }

        const pickLanguage = async () => {
            const languageSelect = await openSelectModal(language.value.languages, 'radio', languageSelectOptions.value, false);
            languageSelect.onWillDismiss().then(({data}: any) => {
                languageSelectOptions.value = data;
                appInstance.value.languageId = data.find((option: SelectOption) => option.isChecked)?.value;
                const selectedText = data.find((option: SelectOption) => option.isChecked)?.text;
                if (selectedText) {
                    appInstance.value.language.language = selectedText.split(',')[0];
                    appInstance.value.language.code = selectedText.split(',')[1];
                }
            });
        }
      
        const pickRefOnly = async () => {
            const yesNoSelect = await openSelectModal(language.value.displayScriptureRefOnlyQuestion, 'radio', displayScriptureRefOnlyOptions.value, false);
            yesNoSelect.onWillDismiss().then(({data}: any) => {
                appInstance.value.displayScriptureRefOnly = data.find((option: SelectOption) => option.isChecked)?.value === "true";
            });
        }

        const pickSuspended = async () => {
          const yesNoSelect = await openSelectModal(language.value.suspendedToggle, 'radio', suspendedOptions.value, false);
          yesNoSelect.onWillDismiss().then(({data}: any) => {
            appInstance.value.suspended = data.find((option: SelectOption) => option.isChecked)?.value === "true";
          });
        }

        const clear = (element: any) => {
            element.value = '';
            element.dispatchEvent(new Event('change'));
        }

        const languageFileChanged = (event: any) => {
            if (event.target.files.length > 0) {
                appInstance.value.languageFilename = event.target.files[0].name;
                const reader = new FileReader();
                reader.onloadend = () => {
                    appInstance.value.languageFile = reader.result as string;
                };
                reader.readAsDataURL(event.target.files[0]);
            } else {
                appInstance.value.languageFilename = '';
                appInstance.value.languageFile = '';
            }
        }

        const scriptureFileChanged = (event: any) => {
            if (event.target.files.length > 0) {
                appInstance.value.scriptureFilename = event.target.files[0].name;
                const reader = new FileReader();
                reader.onloadend = () => {
                    appInstance.value.scriptureFile = reader.result as string;
                };
                reader.readAsDataURL(event.target.files[0]);
            } else {
                appInstance.value.scriptureFilename = '';
                appInstance.value.scriptureFile = '';
            }
        }

        const submitForm = async () => {
            if (appInstance.value.name.trim() === '') {
                presentValidationAlert(language.value.validation.enterName);
            } else if (appInstance.value.partnerId === '') {
                presentValidationAlert(language.value.validation.selectPartner);
            } else {
                await execute(async () => {
                    const data = await PUT('/instances', appInstance.value);
                    if (data) {
                        const selectedAppInstanceId = store.getters['app/selectedAppInstanceId'];
                        const changingSelectedAppInstanceLanguage = currentId.value !== EMPTY_GUID && currentId.value === selectedAppInstanceId && originalLanguageData.value !== appInstance.value.languageFile;
                        if (changingSelectedAppInstanceLanguage) {
                            await store.dispatch('app/setSelectedAppInstanceId', selectedAppInstanceId);
                        }
                        router.push({name: 'appInstances'});
                    }
                }, language.value.errors.savingAppInstance);
            }
        }

        return {
            appInstance,
            currentId,
            footerButtons: computed(() => {
                return ([{
                    class: 'custom-button button-clear',
                    icon: false,
                    iconPos: '',
                    handler: () => router.back(),
                    text: language.value.cancel
                },
                    {
                        class: 'custom-button button-primary',
                        icon: false,
                        iconPos: '',
                        handler: () => submitForm(),
                        text: currentId.value === EMPTY_GUID ? language.value.addInstance : language.value.saveEdits
                    }]);
            }),
            caretDownOutline,
            closeOutline,
            openSelectModal,
            partnersSelectOptions,
            pickPartner,
            languageSelectOptions,
            pickLanguage,
            pickRefOnly,
            pickSuspended,
            languageFileChanged,
            scriptureFileChanged,
            languageFile,
            scriptureFile,
            clear,
            canEditSubdomain,
            language,
            store,
            originalLanguageData,
            partnerDisabledStyle: computed(() => currentId.value !== EMPTY_GUID ? 'ion-item-disabled' : '')
        }
    }
});
